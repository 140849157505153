import { Container, Flex, rem, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import Image from "next/image";
import Link from "next/link";
export default function AgendamentoCard() {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const tablet = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const middleScreen = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);

  return (
    <Container
      bg={"#828C34"}
      size={"xl"}
      mt={400}
      mb={100}
      h={mobile || tablet ? "auto" : rem(600)}
    >
      <Flex
        w={{ sm: "100%", md: "100%" }}
        direction={{ base: "column", sm: "row" }}
        p={mobile || tablet ? 10 : 0}
        justify={{ sm: "center", md: "100%" }}
        align={{ sm: "center", md: "100%" }}
      >
        <div
          className={`bg-white  ${
            mobile || tablet ? "w-full" : "w-4/5"
          } h-auto p-12`}
        >
          <div className="flex flex-col scale-90 gap-8">
            <span className="w-full text-light-textoEscuroCTX text-4xl leading-snug">
              Quer agendar sua consulta com a Dra. Gabriella Martins?
            </span>
            <span className="w-full text-light-textoEscuroCTX text-xl">
              Agende sua consulta com a Dra. Gabriella Martins e saiba como o
              tratamento holístico e natural pode ser efetivo para você.
            </span>
            <div className="flex justify-center w-full">
              <Link
                href={
                  "https://agendaronline.amplimed.com.br/gabriella-de-oliveira-martins"
                }
                target="_blank"
                className="w-full"
              >
                <button className="bg-light-verdeEscuroCTX p-6 w-full rounded-full text-white font-bold">
                  AGENDAR CONSULTA
                </button>
              </Link>
            </div>
          </div>
        </div>

        <Image
          src="/dra.png"
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          width={771}
          height={1055}
          className={`${
            mobile || tablet
              ? "hidden"
              : middleScreen
              ? "-mr-18 -mt-[6.5rem]"
              : "-mr-32 -mt-[18.3rem]"
          }`}
          loading="lazy"
          alt="canabis no cha"
        />
      </Flex>
    </Container>
  );
}
