import {
  Container,
  createStyles,
  Flex,
  rem,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import Image from "next/image";
import { useCallback } from "react";

const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: rem(120),
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    backgroundColor: "#fff",
  },
}));

export function FooterAction() {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const tablet = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  const handleWhatsapp = useCallback(() => {
    window.open(
      "https://api.whatsapp.com/send/?phone=%2B5535999964018&text&type=phone_number&app_absent=0"
    );
  }, []);

  return (
    <footer className={classes.footer}>
      <Container size={"91rem"} mb={rem(100)}>
        <Flex
          w={{ sm: "100%", md: "100%" }}
          direction={{ base: "column", sm: "row" }}
          gap={{ base: "sm", sm: "lg" }}
          justify={{ sm: "center", md: "100%" }}
          align={{ sm: "center", md: "100%" }}
        >
          <Flex
            w={{ sm: "100%", md: "100%" }}
            direction={{ base: "column", sm: "row" }}
            gap={{ base: "sm", sm: "lg" }}
            justify={{ sm: "space-evenly", md: "100%" }}
            align={{ sm: "center", md: "100%" }}
            ml={rem(30)}
          >
            <Image
              src={"/selo-verde-para-fundo-branco.png"}
              alt="Icone da logo"
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 70vw, 43vw"
              width={300}
              height={300}
            />
            <span className="text-light-textoClaroCTX text-xl w-full leading-snug">
              Cuide da sua saúde com confiança e um cuidado médico dedicado e
              confiável, visando o seu bem-estar em primeiro lugar.
            </span>
            <div
              className={`flex flex-col justify-start items-start w-full mt-10 mb-10 ${
                mobile || tablet ? "" : "ml-24"
              }`}
            >
              <span className="text-light-textoClaroCTX text-xl w-full leading-snug">
                Entre em contato conosco
              </span>
              <button
                onClick={handleWhatsapp}
                className="bg-light-verdeEscuroCTX p-3 w-3/4 mt-5 rounded-full text-white font-bold"
              >
                Whatsapp
              </button>
            </div>
            {/* <HoverCard shadow="xl" radius={"lg"}>
              <HoverCard.Target>
                <Image
                  src={"/footer/social_media.svg"}
                  alt="Redes Socias"
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 70vw, 43vw"
                  width={150}
                  height={150}
                />
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Table>
                  <thead>
                    <tr>
                      <th>Rede</th>
                      <th>Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key={"Youtube"}>
                      <td>Youtube</td>
                      <td>Link Youtube</td>
                    </tr>
                    <tr key={"Twitter"}>
                      <td>Twitter</td>
                      <td>Link Twitter</td>
                    </tr>
                    <tr key={"Instagram"}>
                      <td>Instagram</td>
                      <td>Link Instagram</td>
                    </tr>
                  </tbody>
                </Table>
              </HoverCard.Dropdown>
            </HoverCard> */}
          </Flex>
        </Flex>
        <Flex
          mt={rem(50)}
          w={{ sm: "100%", md: "100%" }}
          direction={{ base: "column", sm: "row" }}
          gap={{ base: "sm", sm: "lg" }}
          justify={{ sm: "center", md: "100%" }}
          align={{ sm: "center", md: "100%" }}
        >
          <Flex
            w={{ sm: "100%", md: "100%" }}
            direction={{ base: "column", sm: "row" }}
            gap={{ base: "sm", sm: "lg" }}
            justify={{ sm: "space-evenly", md: "100%" }}
            align={{ sm: "center", md: "100%" }}
            ml={rem(30)}
          >
            <div
              className={`flex justify-start items-center gap-5 w-full  ${
                mobile || tablet ? "my-4 break-all" : "ml-12 "
              }`}
            >
              <Image
                src={"/footer/phone_icon.svg"}
                alt="Redes Socias"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 70vw, 43vw"
                width={24}
                height={24}
              />
              <span
                onClick={handleWhatsapp}
                className="text-light-textoClaroCTX cursor-pointer text-xl w-full leading-snug"
              >
                +55 35 99996‑4018
              </span>
            </div>
            <div
              className={`flex justify-start items-center gap-5 w-full  ${
                mobile || tablet ? "my-4 break-all" : "ml-12 "
              }`}
            >
              <Image
                src={"/footer/web_icon.svg"}
                alt="Redes Socias"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 70vw, 43vw"
                width={24}
                height={24}
              />
              <span className="text-light-textoClaroCTX text-xl w-full leading-snug">
                @dragabriellamartins
              </span>
            </div>
            <div
              className={`flex justify-start items-center gap-5 w-full  ${
                mobile || tablet ? "my-4 break-all" : "ml-12 "
              }`}
            >
              <Image
                src={"/footer/mail_icon.svg"}
                alt="Redes Socias"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 70vw, 43vw"
                width={24}
                height={24}
              />
              <span className="text-light-textoClaroCTX text-xl w-full leading-snug">
                dragabriellamartins@gmail.com
              </span>
            </div>
            <div
              className={`flex justify-start items-center gap-5 w-full  ${
                mobile || tablet ? "my-4 break-all" : "ml-12 "
              }`}
            >
              <Image
                src={"/footer/correio_icon.svg"}
                alt="Redes Socias"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 70vw, 43vw"
                width={24}
                height={24}
              />
              <span className="text-light-textoClaroCTX text-xl w-full leading-snug">
                Atendimento Online
              </span>
            </div>
          </Flex>
        </Flex>
      </Container>
      <div
        className="w-full flex items-center flex-wrap"
        style={{
          borderTop: "1px solid #828C34",
        }}
      >
        <span className="text-light-textoClaroCTX text-sm ml-20 mt-20 w-full leading-snug">
          ©2023 Site desenvolvido pela Agência Viss Ltda.
        </span>
      </div>
    </footer>
  );
}
