import { BenefitGrid } from "@/components/BenefitGrid";
import CannabisHistory from "@/components/CannabisHistory";
import Inicio from "@/components/Inicio";
import { SobreMim } from "@/components/SobreMim";
import {
  Container,
  Divider,
  Group,
  SimpleGrid,
  Text,
  Title,
} from "@mantine/core";
import Image from "next/image";

import AgendamentoCard from "@/components/AgendamentoCard";
import { FooterAction } from "@/components/commom/FooterAction";
import HeaderAction from "@/components/commom/HeaderAction";
import { HEADER_VALUES } from "@/utils/consts";
import Head from "next/head";

export default function Home() {
  return (
    <main className={`min-h-screen`}>
      <Head>
        <title>Dra. Gabriella Martins</title>
        <meta name="description" content="Dra. Gabriella Martins"></meta>
      </Head>
      <HeaderAction links={HEADER_VALUES} />
      <Inicio />
      <Container id="sobre_a_canabis" className="scale-90" size={"xl"} mt={200}>
        <SimpleGrid
          spacing={90}
          cols={2}
          breakpoints={[
            { maxWidth: "md", cols: 2, spacing: "md" },
            { maxWidth: "sm", cols: 2, spacing: "sm" },
            { maxWidth: "xs", cols: 1, spacing: "sm" },
          ]}
        >
          <Group spacing={4}>
            <span className="text-light-textoClaroCTX text-4xl  w-full leading-snug">
              Medicina Canabinoide: Inovação Terapêutica e Uso Responsável da
              Cannabis Medicinal
            </span>
            <Divider size={"md"} w={80} color="#D6FF93" />
          </Group>

          <span className="text-light-textoClaroCTX text-xl">
            Bem-vindo ao nosso site dedicado à Medicina Canabinoide, onde
            oferecemos informações cientificamente embasadas e orientações sobre
            o uso responsável da cannabis medicinal. Aqui, exploraremos a
            história, a situação jurídica, os compostos da cannabis, o sistema
            endocanabinoide, as condições tratáveis e os avanços científicos
            relacionados a essa modalidade terapêutica.
          </span>
        </SimpleGrid>
      </Container>
      <BenefitGrid />
      <Image
        src="/canabis_no_cha.png"
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        width={0}
        height={0}
        quality={100}
        className="w-full"
        loading="lazy"
        alt="canabis no cha"
      />
      <Container className="" size={"lg"} mt={100} mb={100}>
        <CannabisHistory />
      </Container>
      <Image
        src="/canabis_na_planta.png"
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        width={4000}
        height={4000}
        quality={100}
        className="w-full"
        loading="lazy"
        alt="canabis na planta"
      />
      <Container size={"xl"} mt={100}>
        <Container size={"xl"}>
          <Group spacing={6} mt={60}>
            <Title className="text-light-textoClaroCTX text-4xl font-medium w-full leading-snug">
              Uso recreativo versus medicinal da cannabis:
            </Title>
            <Divider size={"md"} w={300} color="#D6FF93" />
            <Text align="left" size={"lg"} color="#00332D">
              <p className="m-10 ml-0">
                É importante destacar a diferenciação entre o uso recreativo e o
                uso medicinal da cannabis. O uso recreativo da cannabis busca a
                experiência de relaxamento, euforia ou alterações na percepção,
                com fins de recreação, entretenimento ou relaxamento sem fins
                terapêuticos.
              </p>
              <p className="m-10 ml-0">
                Por outro lado, o uso medicinal da cannabis refere-se à
                utilização da planta como uma abordagem terapêutica para tratar
                sintomas e condições médicas específicas. Nesse contexto, a
                cannabis é utilizada com o intuito de aliviar dores crônicas,
                reduzir inflamações, controlar convulsões, combater náuseas e
                vômitos relacionados a tratamentos de saúde, além de auxiliar no
                tratamento de doenças neurológicas, distúrbios do sono,
                ansiedade e outros problemas de saúde. O efeito comitiva,
                resultado da interação sinérgica entre os compostos da cannabis,
                como os terpenos, o THC e o CBD, desempenha um papel importante
                nesses benefícios terapêuticos.
              </p>
              <p className="m-10 ml-0">
                A Medicina Canabinoide está focada em explorar e maximizar os
                benefícios terapêuticos da cannabis, empregando protocolos
                específicos, dosagens adequadas e monitoramento médico contínuo.
                A utilização da cannabis medicinal é regulamentada por órgãos
                competentes, como a ANVISA, e requer a prescrição médica para
                garantir o uso responsável e seguro. Embora exista essa
                diferenciação entre os usos recreativo e medicinal da cannabis,
                é fundamental lembrar que a Medicina Canabinoide não tem o
                objetivo de julgar ou estigmatizar o uso recreativo. Cada pessoa
                possui suas próprias necessidades e escolhas, e o importante é
                que todas as formas de uso sejam realizadas com
                responsabilidade, dentro das normas legais e respeitando os
                limites individuais.
              </p>

              <p className="m-10 ml-0">
                Nossa plataforma está comprometida em fornecer informações
                precisas e confiáveis sobre a Medicina Canabinoide, bem como
                orientações sobre o uso responsável da cannabis medicinal. Nosso
                objetivo é oferecer suporte aos pacientes, fornecendo
                conhecimentos embasados cientificamente, esclarecendo dúvidas e
                promovendo a conscientização sobre os benefícios terapêuticos da
                cannabis medicinal. Agende uma consulta online para obter
                informações personalizadas e orientações sobre o uso adequado da
                cannabis medicinal para suas necessidades específicas.
              </p>
            </Text>
          </Group>
        </Container>
      </Container>
      <SobreMim />
      {/* <OpinionsCarousel /> */}
      <AgendamentoCard />

      <FooterAction />
    </main>
  );
}
